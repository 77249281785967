import React, { useRef } from 'react';
import { RightSlideModalWrapper } from '../../components/ModalTypes/right_slide';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import s from './index.module.scss';

export const FpgServiceInfoModal = ({ text, onClose }) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => onClose(), true);

  return (
    <RightSlideModalWrapper onClose={onClose}>
      <div className={s.wrapper} ref={ref}>
        <div
          className={s.text}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <button type="button" onClick={onClose} className={s.close_btn}>
          Got it
        </button>
      </div>
    </RightSlideModalWrapper>
  );
};
