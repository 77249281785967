import React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { DATE_FORMAT_DD_MMM } from '../../../../helpers/common';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { MyLink } from '../../../../components/MyLink';
import { APP_LINKS } from '../../../../helpers/links';
import Dropdown from '../../../../components/Dropdown';

export const RepaymentHistory = () => {
  const { paymentHistory } = useSelector(state => state.loanStore);
  const list = paymentHistory.data?.list || [];

  return (
    <div className="loan-info-payments">
      <Dropdown label={<h2 className="f-22 f-500">Repayment history</h2>}>
        {list.length ? (
          <div className="loan-info-payments__table">
            <div className="loan-info-payments__thead">
              <div className="loan-info-payments__thead-col">Date</div>
              <div className="loan-info-payments__thead-col">Amount</div>
              <div className="loan-info-payments__thead-col">Status</div>
            </div>

            <div className="loan-info-payments__tbody">
              {list.map((item, idx) => (
                <div className="loan-info-payments__tbody-row" key={idx}>
                  <div className="loan-info-payments__tbody-col">
                    {moment(item.created_at).format(DATE_FORMAT_DD_MMM).replace('.', '')}
                  </div>
                  <div className="loan-info-payments__tbody-col">
                    {prettyFloatMoney(item.amount)}
                  </div>
                  <div className="loan-info-payments__tbody-col loan-info-payments__description">
                    {item.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="loan-info__empty-message f-16">
            No repayments yet.
            <MyLink href={APP_LINKS.prepayLoans}> Prepay loans →</MyLink>
          </div>
        )}
      </Dropdown>
    </div>
  );
};
