import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import * as Yup from 'yup';
import IconHelp from '../assets/icon_help_circle.svg';
import { RadioInput } from '../../../kit/RadioInput';
import { RoundLink } from '../../../components/UI/RoundLink';
import { APP_LINKS } from '../../../helpers/links';
import Checkbox from '../../../components/UI/Checkbox';
import { MyLink } from '../../../components/MyLink';
import { RoundButton } from '../../../components/UI/RoundButton';
import { prettyFloatMoney } from '../../../helpers/utils';
import { Goal } from '../components/Goal';
import { DEFAULT_MAX_GOAL, DEFAULT_MIN_GOAL } from '../constants';
import { FpgServiceInfoModal } from '../../../modals/FpgServiceInfoModal';
import s from './index.module.scss';

const VALIDATION_SCHEMA = (min, max) =>
  Yup.object().shape({
    canApply: Yup.bool()
      .oneOf([true], 'Your available cash is less then minimum required')
      .required(''),
    fpg: Yup.number().required('FPG is required'),
    goal: Yup.number()
      .min(min, `Minimal amount ${prettyFloatMoney(min)}`)
      .max(max, `Maximum amount ${prettyFloatMoney(max)}`)
      .required('Goal is required'),
    has_accepted_terms: Yup.bool().oneOf([true], 'Accept terms'),
  });

export const StepSetFundraising = ({ disabled, onSubmit }) => {
  const { data: status } = useSelector(state => state.fundraisingStore.status);
  const [show, setShow] = useState(false);

  const formik = useFormik({
    validationSchema: VALIDATION_SCHEMA(
      (status && status.min_goal) || DEFAULT_MIN_GOAL,
      (status && status.max_goal) || DEFAULT_MAX_GOAL
    ),
    initialValues: {
      goal: status.goal || '',
      fpg: null,
      has_accepted_terms: false,
    },
    onSubmit,
  });

  const { values, touched, errors, setFieldValue, handleSubmit, handleChange } = formik;

  useEffect(() => {
    if (!values.fpg && status?.available_fpg_options.length) {
      const defaultFpg = status?.available_fpg_options.find(option => option.is_default);
      defaultFpg && setFieldValue('fpg', defaultFpg.id);
    }
  }, [values.fpg, status]);

  const onGoalEdit = ({ goal }) => setFieldValue('goal', goal);

  if (!status) return null;

  const canApply = status.max_goal === 0 || status.max_goal > status.min_goal;
  if (!canApply)
    return (
      <div className={s.wrapper}>
        <h1>
          Your fundraising goal could not be approved due to your current debts. If you would like
          to fundraise cash, pay off all your debts first.
        </h1>
        <RoundLink label="Go to payment" path={APP_LINKS.payments} className={s.payment_link} />
      </div>
    );

  return (
    <form className={s.wrapper} onSubmit={handleSubmit}>
      <Goal amount={values.goal} onEdit={onGoalEdit} />
      <div className={s.fpg__wrapper}>
        <div className={s.fpg__title}>
          <span>Select Founder Personal Guarantee (FPG) option</span>
          <button type="button" onClick={() => setShow(true)}>
            <img src={IconHelp} alt="icon" width={24} height={24} />
          </button>
        </div>
        <div className={classnames(s.fpg__desc, 'f-16')}>
          Scramble limits the personal liability of each founder with a cap. Founders must select
          the desired cap level before proceeding
        </div>
        <div
          className={s.fpg__options}
          style={{
            gridTemplateColumns: `repeat(${status.available_fpg_options.length}, minmax(0, 1fr))`,
          }}
        >
          {status.available_fpg_options.map(option => (
            <div
              key={option.id}
              className={classnames(
                s.fpg__option,
                option.id === values.fpg && s.fpg__option_selected
              )}
            >
              <div className={s.fpg__option_top}>
                <h3 className={s.fpg__option_title}>{option.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: option.description,
                  }}
                />
              </div>

              <div className={s.fpg__option_cost}>{option.amount_description}</div>
              <label htmlFor={option.id} className={s.fpg__option_label}>
                <RadioInput
                  id={option.id}
                  name="fpg"
                  value={option.id}
                  onChange={() => setFieldValue('fpg', option.id)}
                  className={s.fpg__option_input}
                  checked={option.id === values.fpg}
                />
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className={s.checkboxes}>
        <Checkbox
          name="has_accepted_terms"
          disabled={disabled}
          isChecked={values.has_accepted_terms}
          onChange={handleChange}
          error={touched.has_accepted_terms && errors.has_accepted_terms}
          render={() => (
            <span
              className={classnames(
                'f-14',
                touched.has_accepted_terms && errors.has_accepted_terms && 'error'
              )}
            >
              I have read the terms and conditions of the{' '}
              <MyLink href={APP_LINKS.docTermsOfUse} underline isExternal>
                Terms of Use
              </MyLink>
              , the{' '}
              <MyLink href={APP_LINKS.docLoanSeveranceAgreement} underline isExternal>
                Severance Agreement
              </MyLink>
              , the{' '}
              <MyLink href={APP_LINKS.docAssignmentAgreement} underline isExternal>
                Assignment Agreement
              </MyLink>
              , the{' '}
              <MyLink href={APP_LINKS.suretyAgreement} underline isExternal>
                Surety Agreement
              </MyLink>
              , and I agree to conclude them as a result of the current round.
            </span>
          )}
        />
      </div>

      <div className={s.buttons}>
        <RoundButton
          type="submit"
          label="Set fundraising goal"
          disabled={disabled}
          onSubmit={handleSubmit}
          fillBackground
          fullWidth
        />
      </div>

      {show && <FpgServiceInfoModal text={status.fpg_description} onClose={() => setShow(false)} />}
    </form>
  );
};
