import React, { useState } from 'react';
import classnames from 'classnames';
import IconPen from '../../assets/icon_pencil.svg';
import { GoalEditModal } from '../../../../modals/GoalEditModal';
import { prettyFloatMoney } from '../../../../helpers/utils';
import s from './index.module.scss';

export const Goal = ({ amount, onEdit }) => {
  const [show, setShow] = useState(false);

  return (
    <div className={s.goal__wrapper}>
      <div className={s.goal}>
        <div className={s.goal__value}>{prettyFloatMoney(amount)}</div>
        {onEdit && (
          <button type="button" className={s.goal__edit} onClick={() => setShow(true)}>
            <img src={IconPen} alt="icon" width={24} height={24} />
          </button>
        )}
      </div>

      <div className={classnames(s.goal__desc, 'f-14')}>Your next fundraising goal</div>

      {onEdit && show && (
        <GoalEditModal
          amount={amount}
          onSubmit={props => {
            setShow(false);
            onEdit(props);
          }}
          onClose={() => setShow(false)}
        />
      )}
    </div>
  );
};
