import React from 'react';
import { useSelector } from 'react-redux';
import { Message } from '../../../kit/Message';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import { SelectedFPG } from '../components/SelectedFPG';
import { Goal } from '../components/Goal';
import s from './index.module.scss';

export const FundraisingStartModule = () => {
  const { data: status } = useSelector(state => state.fundraisingStore.status);
  const { goal, end_date } = status;

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className={s.badge}>Fundraising round in progress</div>
        <Goal amount={goal} />
        <SelectedFPG className={s.fpg} />
        <Message type="success" className={s.message}>
          {NOTIFICATIONS.loan_disbursements_starts(end_date)()}
        </Message>
      </div>
    </div>
  );
};
