import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Preloader from '../../components/UI/Preloader';
import { FundraisingStartModule } from './FundraisingStartModule';
import FundraisingResultsModule from './FundraisingResultsModule';
import { FundraisingSettings } from './FundraisingSettings';
import FundraisingOnHold from './FundraisingOnHold';
import { StepSetFundraising } from './StepSetFundraising';
import { StepWaitAdministrator } from './StepWaitAdministrator';
import { setModals } from '../../store/actions/commonActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { getMissingProfileDetails, getUserProfile } from '../../store/actions/profileActions';
import { ROLES, USER_STATUSES } from '../../helpers/constants';
import { isVerifiedPhoneNumber } from '../../helpers/user';
import {
  getCoFoundersList,
  getFundraisingStatus,
  getNextFundraisingStatus,
  getPartnerCounts,
  resetFundraisingGoal,
  setFundraisingGoal,
} from '../../store/actions/fundraisingActions';
import './index.scss';

const FundraisingModule = () => {
  const [state, setState] = useState({
    currentStep: 1,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.userStore.user);
  const profile = useSelector(state => state.profileStore.profile);
  const status = useSelector(state => state.fundraisingStore.status);

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getPartnerCounts());
    dispatch(getMissingProfileDetails());
    dispatch(getFundraisingStatus()).then(res => {
      if (res && res.success) {
        let step = res.data.step;
        step === 1 && dispatch(getCoFoundersList());
        const redirectStep = Number(qs.parse(location.search.replace('?', '')).to);
        if (
          (redirectStep === 1 || redirectStep === 2) &&
          !res.data.is_active &&
          res.data.step < 4
        ) {
          step = redirectStep;
        }
        redirectStep && history.replace('/fundraising');
        setState(prevState => ({
          ...prevState,
          currentStep: step,
        }));
      }
    });
  }, []);

  const setGoal = values => {
    console.log('HERE');
    const { goal, fpg, has_accepted_terms } = values;
    const payload = {
      goal,
      fpg_option: fpg,
      has_accepted_terms,
    };
    dispatch(setFundraisingGoal(payload)).then(res => {
      res &&
        res.success &&
        setState(prevState => ({
          ...prevState,
          currentStep: res.data.step,
        }));
    });
  };

  const resetFundraising = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.confirm_modal,
        onConfirm: () => {
          dispatch(resetFundraisingGoal()).then(res => {
            if (res && res.success) {
              setState(prevState => ({
                ...prevState,
                currentStep: res.data.step,
              }));
              dispatch(setModals([]));
            }
          });
        },
        title: 'Changed your mind?',
        desc: 'You can refuse to participate in the round before it starts',
      })
    );
  };

  const setNewFundraising = () => {
    dispatch(getNextFundraisingStatus()).then(res => {
      res &&
        res.success &&
        setState(prevState => ({
          ...prevState,
          currentStep: res.data.step,
        }));
    });
  };

  const { currentStep } = state;
  const disabled =
    user.status === USER_STATUSES.blocked ||
    user.status === USER_STATUSES.new ||
    user.role !== ROLES.founder ||
    !status.data?.max_goal ||
    !isVerifiedPhoneNumber(profile?.data?.user);

  return (
    <div className="fundraising-module__wrap">
      <div className="container-inner">
        {status.loading ? (
          <Preloader className="fundraising-module__preloader" />
        ) : (
          status.data && (
            <div className={classnames('fundraising-module__content', user.role)}>
              {currentStep === 1 && user.role === ROLES.founder && (
                <StepSetFundraising disabled={disabled} onSubmit={setGoal} />
              )}

              {currentStep === 1 && user.role === ROLES.co_founder && <StepWaitAdministrator />}

              {currentStep === 2 &&
                (status.data.is_active ? (
                  <FundraisingStartModule />
                ) : (
                  <FundraisingSettings onReset={resetFundraising} />
                ))}
              {currentStep === 3 && <FundraisingOnHold />}
              {currentStep === 4 && (
                <FundraisingResultsModule setNewFundraising={setNewFundraising} />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FundraisingModule;
