import React from 'react';
import classnames from 'classnames';
import IconSuccess from './assets/icon_success_18.svg';
import IconError from './assets/icon_danger_18.svg';
import IconInfo from './assets/icon_info_18.svg';
import IconWarning from './assets/icon_warning_18.svg';
import s from './index.module.scss';

const TYPES = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export const Message = ({ type, title, description, children, className }) => {
  return (
    <div className={classnames(s.block, type, className)}>
      {type === TYPES.info && <img src={IconInfo} alt="icon" className={s.icon} />}
      {type === TYPES.warning && <img src={IconWarning} alt="icon" className={s.icon} />}
      {type === TYPES.error && <img src={IconError} alt="icon" className={s.icon} />}
      {type === TYPES.success && <img src={IconSuccess} alt="icon" className={s.icon} />}
      <div className={s.content}>
        {children || (
          <>
            <div className={s.title}>{title}</div>
            {description && <div className={s.desc}>{description}</div>}
          </>
        )}
      </div>
    </div>
  );
};
