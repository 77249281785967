import React from 'react';
import { useSelector } from 'react-redux';
import { Goal } from '../components/Goal';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { isVerifiedPhoneNumber } from '../../../helpers/user';
import { Message } from '../../../kit/Message';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import s from './index.module.scss';

export const StepWaitAdministrator = () => {
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { data: status } = useSelector(state => state.fundraisingStore.status);

  if (!status) return null;

  const initialGoal = status
    ? status.goal
      ? status.goal.toString()
      : status.max_goal < status.initial_limit
      ? status.max_goal.toString()
      : status.initial_limit.toString()
    : '';

  return (
    <div className={s.wrapper}>
      <Goal amount={initialGoal} />
      <div className={s.messages}>
        <Message
          type="info"
          title="Wait while your company administrator sets up new fundraising"
        />
        {profile && !isVerifiedPhoneNumber(profile.user) && (
          <Message type="error">
            <>
              <div>Verify your phone number</div>
              <MyLink href={APP_LINKS.profile + '#phone'} className={s.confirm_link}>
                Confirm
              </MyLink>
            </>
          </Message>
        )}
        <Message type="success" className={s.message}>
          {NOTIFICATIONS.loan_disbursements_starts(status.end_date)()}
        </Message>
      </div>
    </div>
  );
};
