import React, { useState } from 'react';
import * as classnames from 'classnames';
import './index.scss';

const Dropdown = ({
  title,
  description,
  className,
  defaultShow,
  disableToggle,
  label,
  children,
  ...props
}) => {
  const [show, setShow] = useState(!!defaultShow);
  return (
    <div
      className={classnames('dropdown', disableToggle && 'disabled', show && 'active', className)}
      {...props}
    >
      <div className="dropdown__header" onClick={() => !disableToggle && setShow(!show)}>
        {label || <span className="f-22 f-500">{title || 'Founders batch'}</span>}

        {!disableToggle && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            style={{ minWidth: '16px' }}
          >
            <path
              d="M5.52866 5.63802L8.39066 8.50002L5.52866 11.362L6.47132 12.3047L10.276 8.50002L6.47132 4.69535L5.52866 5.63802Z"
              fill="black"
            />
          </svg>
        )}
      </div>
      {description && <p className="dropdown__description f-16 f-400">{description}</p>}
      <div className="dropdown__content">{children}</div>
    </div>
  );
};

export default Dropdown;
