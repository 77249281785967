import React from 'react';
import { prettyFloatMoney } from '../../../../helpers/utils';
import { MyLink } from '../../../../components/MyLink';
import { getTariffPlanTermsLink } from '../../../../helpers/loanTerms';

export const LoanDetails = ({ loans }) => {
  if (!loans) return null;

  return (
    <div className="loan-info-details">
      <h2 className="f-22 f-500">Details</h2>

      <div className="loan-info-details__table">
        {loans[0].fees.success_fee ? (
          <div className="loan-info-details__thead success_fee">
            <div className="loan-info-details__thead-col">Loan amount</div>
            <div className="loan-info-details__thead-col">Success fee</div>
            <div className="loan-info-details__thead-col">Outstanding</div>
            <div className="loan-info-details__thead-col">Interest rate</div>
            <div className="loan-info-details__thead-col">Loan group</div>
          </div>
        ) : (
          <div className="loan-info-details__thead six_fee">
            <div className="loan-info-details__thead-col">Loan amount</div>
            <div className="loan-info-details__thead-col">Outstanding</div>
            <div className="loan-info-details__thead-col">Loan term</div>
            <div className="loan-info-details__thead-col">Loan group</div>
          </div>
        )}

        {loans[0].fees.success_fee ? (
          <div className="loan-info-details__tbody">
            {loans.map((loan, idx) => (
              <div className="loan-info-details__tbody-row success_fee" key={idx}>
                <div className="loan-info-details__tbody-col">
                  {prettyFloatMoney(loan.total_amount)}
                </div>
                <div className="loan-info-details__tbody-col">
                  {prettyFloatMoney(loan.fees.success_fee)}
                </div>
                <div className="loan-info-details__tbody-col">
                  {prettyFloatMoney(loan.outstanding)}
                </div>
                <div className="loan-info-details__tbody-col">{`${Number(
                  loan.interest_rate
                )}%`}</div>
                <div className="loan-info-details__tbody-col">{loan.risk_group.title}</div>
              </div>
            ))}
          </div>
        ) : (
          <div className="loan-info-details__tbody">
            {loans.map((loan, idx) => (
              <div className="loan-info-details__tbody-row six_fee" key={idx}>
                <div className="loan-info-details__tbody-col">
                  {prettyFloatMoney(loan.total_amount)}
                </div>
                <div className="loan-info-details__tbody-col">
                  {prettyFloatMoney(loan.outstanding)}
                </div>
                <div className="loan-info-details__tbody-col">{loan.loan_term || '-'}</div>
                <div className="loan-info-details__tbody-col">
                  <MyLink href={getTariffPlanTermsLink(loan.tariff_plan)} underline>
                    {loan.risk_group.title}
                  </MyLink>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
