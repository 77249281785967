import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import IconHelp from '../../assets/icon_help_circle.svg';
import { FpgServiceInfoModal } from '../../../../modals/FpgServiceInfoModal';
import s from './index.module.scss';

export const SelectedFPG = ({ className }) => {
  const { data: status } = useSelector(state => state.fundraisingStore.status);
  const [show, setShow] = React.useState(false);

  if (!status?.selected_fpg) return null;

  return (
    <div className={classnames(s.wrapper, className)}>
      <span>Selected FPG option: {status.selected_fpg.title}</span>
      <img
        src={IconHelp}
        alt="Help icon"
        width={24}
        height={24}
        onClick={() => setShow(true)}
        style={{ cursor: 'pointer' }}
      />
      {show && <FpgServiceInfoModal text={status.fpg_description} onClose={() => setShow(false)} />}
    </div>
  );
};
