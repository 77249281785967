import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Avatar from '../../../components/UI/Avatar';
import { getNameInitials } from '../../../helpers/utils';
import {
  getCoFoundersList,
  participateFundraising,
} from '../../../store/actions/fundraisingActions';
import { ROLES, USER_STATUSES } from '../../../helpers/constants';
import CoFounderFundraisingAcceptForm from '../../../components/Forms/CoFounderFundraisingAcceptForm';
import { MyLink } from '../../../components/MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { isVerifiedPhoneNumber } from '../../../helpers/user';
import { Goal } from '../components/Goal';
import { Message } from '../../../kit/Message';
import parentStyle from '../index.module.scss';
import { SelectedFPG } from '../components/SelectedFPG';
import s from './index.module.scss';

export const FundraisingSettings = ({ onReset }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userStore.user);
  const status = useSelector(state => state.fundraisingStore.status);
  const coFounders = useSelector(state => state.fundraisingStore.coFounders);
  const profile = useSelector(state => state.profileStore.profile.data);

  useEffect(() => {
    dispatch(getCoFoundersList());
  }, []);

  const onParticipationSubmit = values => {
    dispatch(participateFundraising({ status: values.has_accepted_settings })).then(res => {
      if (res && res.success) {
        dispatch(getCoFoundersList());
      }
    });
  };

  return (
    <div className={s.fundraising_settings}>
      <div className={s.header}>
        <div>
          {status.data.is_active && <div className={s.badge}>Fundraising round in progress</div>}
          <Goal amount={status.data.goal} />
          <SelectedFPG />
        </div>
        {!(user.role === ROLES.co_founder && !status.data.is_confirmed) && (
          <button type="button" onClick={onReset} className={parentStyle.reset_btn}>
            Reset fundraising
          </button>
        )}
      </div>

      <div className={s.messages}>
        {profile && !isVerifiedPhoneNumber(profile.user) && (
          <Message type="error">
            <>
              <div>Verify your phone number</div>
              <MyLink href={APP_LINKS.profile + '#phone'} className={s.confirm_link}>
                Confirm
              </MyLink>
            </>
          </Message>
        )}
      </div>

      {user.role === ROLES.co_founder && !status.data.is_confirmed && (
        <CoFounderFundraisingAcceptForm
          onSubmit={onParticipationSubmit}
          className={s.fundraising_settings__participate}
          status={status.data}
          disabled={user.status !== USER_STATUSES.verified}
        />
      )}

      {coFounders.data && !!coFounders.data.length && coFounders.data.length > 1 && (
        <div className={s.fundraising_settings__co_founders}>
          <h2 className="f-26 f-500">Confirmation from founders</h2>
          <p className={classnames(s.fundraising_settings__co_founders_desc, 'f-16', 'f-400')}>
            Before the round starts, co-founders should confirm their agreement to participate in
            the round with the specified fundraising settings.
          </p>
          {coFounders.data.map(founder => (
            <div className={s.fundraising_settings__co_founder} key={founder.id}>
              <MyLink
                href={
                  user.role_id === founder.id
                    ? APP_LINKS.profile
                    : `${APP_LINKS.founders}/${founder.id}`
                }
                className={s.fundraising_settings__co_founder_left}
              >
                <Avatar
                  size={56}
                  alt={founder.avatar.name}
                  src={founder.avatar.file}
                  nameInitials={getNameInitials(founder.first_name, founder.last_name)}
                  active={founder.is_confirmed}
                  className={s.fundraising_settings__co_founder_avatar}
                />
                <div className={s.fundraising_settings__co_founder_content}>
                  <div className="f-18 f-500 tl">{founder.full_name}</div>
                  <div className="f-14 f-400">Co-founder</div>
                </div>
              </MyLink>
              <div className={s.fundraising_settings__co_founder_right}>
                <div
                  className={classnames(
                    s.fundraising_settings__co_founder_status,
                    founder.is_confirmed && s.active
                  )}
                >
                  {founder.is_confirmed ? 'Confirmed ' : 'Not ready yet'}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
