import React from 'react';
import { createPortal } from 'react-dom';
import Icon from './icon_close.svg';
import s from './index.module.scss';

export const RightSlideModalWrapper = ({ children, onClose }) => {
  return createPortal(
    <div className={s.wrapper}>
      <div className={s.backdrop} onClick={onClose} />
      <div className={s.content}>
        <button type="button" className={s.close_btn} onClick={onClose}>
          <img src={Icon} alt="close" width={10} height={10} />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};
