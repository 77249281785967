import React from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const RadioInput = ({ id, className, ...props }) => {
  return (
    <label htmlFor={id} className={classnames(s.radio_input, className)}>
      <input id={id} type="radio" {...props} />
      <span className={s.radio_input__checkmark} />
    </label>
  );
};
