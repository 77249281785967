import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import qs from 'qs';
import nl2br from 'react-nl2br';
import { useLocation } from 'react-router-dom';
import {
  getFullname,
  getNameInitials,
  padNumber,
  prettyFloatMoney,
  prettyMoney,
  getLoanName,
} from '../../helpers/utils';
import Avatar from '../../components/UI/Avatar';
import { FacebookIcon, TwitterIcon, LinkedInIcon } from '../../components/UI/Icons';
import { RoundButton } from '../../components/UI/RoundButton';
import TruncatedTextByLength from '../../components/UI/TruncatedTextByLength';
import { DATE_FORMAT_MM_YYYY, DATE_FORMAT_YYYY } from '../../helpers/common';
import PhoneNumberField from '../../components/UI/PhoneNumberField';
import NotificationBlock from '../NotificationBlock';
import { setModals } from '../../store/actions/commonActions';
import { sendVerificationCode } from '../../store/services/userServices';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { ROLES, USER_STATUSES } from '../../helpers/constants';
import { NOTIFICATIONS } from '../../components/Notification/list';
import Linker from '../../components/Linker';
// import WarningBlock from '../../components/UI/WarningBlock';
import { MyLink } from '../../components/MyLink';
import { isVerifiedPhoneNumber } from '../../helpers/user';
import './index.scss';

const Profile = ({ founder, fundraising, logoutUser }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const modals = useSelector(state => state.commonStore.modals);
  // const withdrawInfo = useSelector(state => state.paymentStore.withdrawInfo.data);
  // const hasAcceptedBankDetails = withdrawInfo?.external_accounts.length;

  useEffect(() => {
    const { s } = qs.parse(location.search.replace('?', ''));
    if (s) {
      const el = document.getElementById(s);
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (location.hash === '#phone') {
      const el = document.getElementById('phone');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    if (location.hash === '#bank_statement') {
      onCompanyBankStatementEdit();
    }
  }, [location.hash]);

  const { user, company, description, social_networks } = founder;
  const fullName = getFullname(user.first_name, user.last_name);

  const isAboutEmpty = !description && !user.country && !user.date_of_birth;
  const isEmptyCompany = !company.is_created_by_user || !company.name;

  const notifications = [];

  user &&
    !user.first_name &&
    notifications.push(idx => (
      <li key={idx}>
        Provide your personal information to start fundraising.
        <br />
        Fill in your firstname, lastname, citizenship.{' '}
        <button type="button" onClick={onAboutEdit} className="c-grey">
          Provide details →
        </button>
      </li>
    ));

  user &&
    user.phone === null &&
    notifications.push(idx => (
      <li key={idx}>
        Provide your contact details to start fundraising.
        <br />
        Fill in your phone number, residence address.{' '}
        <button onClick={onContactsEdit} className="c-grey">
          Provide details →
        </button>
      </li>
    ));

  company &&
    company.name === ' ' &&
    notifications.push(idx => (
      <li key={idx}>
        Provide your startup details to start fundraising.
        <br />
        Fill in details about your company: name, fullname, registration number, email, phone
        number, address and country.{' '}
        <button type="button" onClick={onCompanyEdit} className="c-grey">
          Provide details →
        </button>
      </li>
    ));

  user &&
    !user.is_verified &&
    notifications.push(idx => (
      <li key={idx}>
        Verify your identity to start fundraising.{' '}
        <button type="button" onClick={onVerifyClick} className="c-grey">
          Start verification process →
        </button>
      </li>
    ));

  user && !isVerifiedPhoneNumber(user) && notifications.push(NOTIFICATIONS.verify_phone());

  const onVerifyClick = () => {
    const el = document.getElementById('veriff');
    if (el) {
      el.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const onAvatarClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_avatar,
      })
    );
  };

  const onAboutEdit = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_about,
      })
    );
  };

  const onContactsEdit = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_contacts,
        onSuccess: payload => {
          !payload.user.is_phone_number_verified && onPhoneVerify(payload.user.phone);
        },
      })
    );
  };

  const onCareerEdit = selected => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_career,
        career: selected,
      })
    );
  };

  const onCareerCreate = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_career,
      })
    );
  };

  const onEducationEdit = selected => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_education,
        education: selected,
      })
    );
  };

  const onEducationCreate = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_education,
      })
    );
  };

  const onCompanyEdit = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_company,
      })
    );
  };

  const onCompanyBankStatementEdit = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.profile_bank_statement,
      })
    );
  };

  const onPhoneVerify = async phone => {
    await sendVerificationCode(phone || user?.phone);
    dispatch(
      setModals({
        type: MODAL_TYPES.phone_verification,
      })
    );
  };

  return (
    <div className="profile">
      <div className="profile__notifications">
        <NotificationBlock
          className="dashboard-module__notifications"
          notifications={notifications}
        />
      </div>
      <div className="profile__header">
        <div className="profile__header-avatar">
          <Avatar
            size={260}
            alt="avatar"
            src={user.photo && user.photo.file}
            active={fundraising?.is_participating}
            nameInitials={getNameInitials(user.first_name, user.last_name)}
          />
          <button type="button" className="profile__header-avatar-camera" onClick={onAvatarClick}>
            <svg
              width="35"
              height="27"
              viewBox="0 0 35 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9784 0H22.0216C22.6417 0 23.4555 0.403594 23.8372 0.90125L26.3222 4.56531C26.565 4.92187 26.9686 5.13844 27.3995 5.13844H32.7436C33.9905 5.13844 34.9989 6.15562 34.9989 7.41672V23.9717C34.9989 25.2306 33.9905 26.2511 32.7436 26.2511H2.25641C1.00953 26.2511 0 25.2328 0 23.9717V7.41672C0 6.15781 1.00953 5.13844 2.25641 5.13844H7.62234C8.03906 5.13844 8.42953 4.93062 8.66578 4.585L11.1617 0.90125C11.5445 0.404687 12.3616 0 12.9784 0ZM25.9678 7.99093C25.9678 8.62093 26.4731 9.13171 27.0965 9.13171C27.721 9.13171 28.2253 8.62093 28.2253 7.99093C28.2253 7.36093 27.721 6.84906 27.0965 6.84906C26.4731 6.84906 25.9678 7.36093 25.9678 7.99093ZM9.59654 15.4088C9.59654 19.821 13.1348 23.3975 17.5 23.3975C21.8651 23.3975 25.4023 19.821 25.4023 15.4088C25.4023 10.9955 21.8651 7.42002 17.5 7.42002C13.1348 7.42002 9.59654 10.9955 9.59654 15.4088ZM11.2908 15.4088C11.2908 11.9405 14.07 9.13175 17.5 9.13175C20.93 9.13175 23.7092 11.9405 23.7092 15.4088C23.7092 18.876 20.93 21.6858 17.5 21.6858C14.07 21.6858 11.2908 18.876 11.2908 15.4088Z"
                fill="#000"
              />
            </svg>
          </button>
        </div>

        <div className="profile__header-info">
          <div className="profile__header-top">
            <div className="profile__header-top-left">
              <div className="profile__header-top-inner">
                <h1 className="profile__header-name f-42 f-500 line2tl">{fullName}</h1>
                <p className="profile__header-company f-16 f-400 tl">{company.name || '-'}</p>
              </div>
              <div className="profile__header-socials">
                {Object.keys(social_networks)
                  .filter(key => key !== 'id')
                  .map((key, idx) => {
                    if (key === 'facebook' && social_networks.facebook) {
                      return <FacebookIcon key={idx} href={social_networks.facebook} />;
                    }
                    if (key === 'linkedin' && social_networks.linkedin) {
                      return <LinkedInIcon key={idx} href={social_networks.linkedin} />;
                    }
                    if (key === 'twitter' && social_networks.twitter) {
                      return <TwitterIcon key={idx} href={social_networks.twitter} />;
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="profile__header-top-right">
              <RoundButton
                label="Logout"
                onClick={logoutUser}
                className="profile-page__logout-btn"
                fillBackground
                fullWidth
              />
            </div>
          </div>

          {!!founder.income_forecast.end && (
            <div className="profile__header-income">
              <div className="f-16 f-400">Income forecast</div>
              <div className="f-26 f-500">
                {`up to ${prettyFloatMoney(founder.income_forecast.end)}`}
              </div>
            </div>
          )}

          {fundraising && (
            <div className="profile__header-fundraising">
              <div className="profile__header-goal">
                <div className="f-16 f-400">Fundraising goal</div>
                <div className="f-26 f-500">{prettyMoney(fundraising.goal)}</div>
              </div>
              <div className="profile__header-loan">
                <div className="f-16 f-400">Accepted loans</div>
                <div className="f-26 f-500">{getLoanName(fundraising.loan_groups)}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="profile__personal-wrap">
        <h2 className="f-32 f-500 profile__title">Personal Info</h2>
        <div className="profile__personal">
          <div className="profile__personal-about">
            <div className="row">
              <h3 className="f-22 f-500">About me</h3>
              <RoundButton
                label={modals?.length ? 'Cancel' : 'Edit'}
                style={{ width: '161px' }}
                onClick={onAboutEdit}
              />
            </div>
            {isAboutEmpty ? (
              <div className="profile__personal-empty">User did not provide any information</div>
            ) : (
              <div className="profile__personal-about-list">
                {user.country && (
                  <div className="f-16 f-400 tl bullet">
                    Citizen of&nbsp;
                    {user.citizenship}
                  </div>
                )}
                {user.date_of_birth && (
                  <div className="f-16 f-400 tl bullet">
                    {moment().diff(moment(user.date_of_birth), 'years')} years old
                  </div>
                )}
              </div>
            )}
          </div>
          {description && (
            <div className="profile__personal-summary">
              <TruncatedTextByLength
                text={nl2br(description)}
                className="profile__personal-summary-text f-16"
                max={1000}
              />
            </div>
          )}
          <div className="profile__personal-contacts" id="contacts">
            <div className="row">
              <h3 className="f-22 f-500">
                Contacts <i className="f-14">(not shown on public profile)</i>
              </h3>
              <RoundButton
                label={modals?.length ? 'Cancel' : 'Edit'}
                style={{ width: '161px' }}
                onClick={onContactsEdit}
              />
            </div>
            <div className="profile__personal-contacts-list">
              {user.id && <div className="f-16 f-400 tl bullet">{padNumber(user.id)}</div>}
              {user.personal_id && (
                <div className="f-16 f-400 tl bullet">{`Personal ID ${user.personal_id}`}</div>
              )}
              {user.email && <div className="f-16 f-400 tl bullet">{user.email}</div>}
              {user.phone && (
                <div className="f-16 f-400 tl bullet">
                  <div className="profile__personal-phone">
                    <PhoneNumberField
                      label="Phone number"
                      name="phone"
                      value={user.phone}
                      countryCode={user.country_code}
                      onChange={() => null}
                      isValid
                      className="profile__personal-contacts-phone"
                      disabled
                    />

                    {!isVerifiedPhoneNumber(user) && (
                      <RoundButton
                        className="profile__personal-phone-verify"
                        label={() => 'Verify'}
                        fillBackground
                        disabled={user.is_phone_number_verified}
                        onClick={onPhoneVerify}
                      />
                    )}
                  </div>
                </div>
              )}
              {user.address && (
                <div className="f-16 f-400 tl bullet">
                  {user.address
                    ? [user.country, user.city, user.address, user.postal_code]
                        .filter(item => item)
                        .join(', ')
                    : '-'}
                </div>
              )}
            </div>
          </div>

          <div className="profile__career">
            <h3 className="f-22 f-500">Career</h3>
            {founder.careers && (
              <ul className="f-16 profile__career-list">
                {founder.careers.map((item, idx) => (
                  <li key={idx} className="f-16" onClick={() => onCareerEdit(item)}>
                    <div className="bullet">
                      {moment(item.start_date).format(DATE_FORMAT_MM_YYYY)}{' '}
                      {!item.is_working
                        ? ` – ${moment(item.end_date).format(DATE_FORMAT_MM_YYYY)}`
                        : ' - present'}
                    </div>
                    <div>{[item.position, item.company].filter(item => item).join(' at ')}</div>
                  </li>
                ))}
              </ul>
            )}
            <button type="button" onClick={onCareerCreate} className="profile__add-btn">
              + add {founder.careers && 'more'}
            </button>
          </div>

          <div className="profile__education">
            <h3 className="f-22 f-500">Education</h3>
            {founder.educations && (
              <ul className="f-16 profile__education-list">
                {founder.educations.map((item, idx) => (
                  <li key={idx} className="f-16" onClick={() => onEducationEdit(item)}>
                    <div className="bullet">
                      {moment(item.start_date).format(DATE_FORMAT_YYYY)}{' '}
                      {!item.is_studying
                        ? ` – ${moment(item.end_date).format(DATE_FORMAT_YYYY)}`
                        : ' - present'}
                    </div>
                    <div>{[item.degree, item.college].filter(item => item).join(', ')}</div>
                  </li>
                ))}
              </ul>
            )}
            <button type="button" onClick={onEducationCreate} className="profile__add-btn">
              + add {founder.educations && 'more'}
            </button>
          </div>
        </div>
      </div>

      {founder.recommendations && (
        <div className="profile__recommendations-wrap">
          <h2 className="f-32 f-500 profile__title">Recommended by these professionals</h2>
          <div className="profile__recommendations">
            {founder.recommendations.map(rec => (
              <div className="profile__review" key={rec.id}>
                <Avatar
                  size={60}
                  alt="avatar"
                  src={rec.photo && rec.photo.file}
                  nameInitials={getNameInitials(rec.first_name, rec.last_name)}
                  className="profile__review-avatar"
                />
                <div className="profile__review-content">
                  <div className="profile__review-header">
                    <h6 className="f-22 f-500">{getFullname(rec.first_name, rec.last_name)}</h6>
                    <div className="profile__review-socials">
                      {rec.facebook && <FacebookIcon href={rec.facebook} />}
                      {rec.linkedin && <LinkedInIcon href={rec.linkedin} />}
                    </div>
                  </div>
                  {(rec.position || rec.company) && (
                    <p className="f-16">
                      {!rec.position && rec.company && rec.company}
                      {rec.position && !rec.company && rec.position}
                      {rec.position && rec.company && `${rec.position} at ${rec.company}`}
                    </p>
                  )}
                  <div className="f-16 profile__review-comment">”{rec.text}”</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="profile__startup-wrap" id="company">
        <div className="row">
          <h2 className="f-32 f-500 profile__title">Company details</h2>
          {user.role === ROLES.founder && (
            <RoundButton
              label={modals?.length ? 'Cancel' : 'Edit'}
              style={{ width: '161px' }}
              onClick={onCompanyEdit}
            />
          )}
        </div>

        {!isEmptyCompany ? (
          <div className="profile__startup">
            <div className="profile__startup-content">
              <div className="profile__startup-header">
                {company.image &&
                  (company.site ? (
                    <MyLink href={company.site} className="profile__startup-logo" isExternal>
                      <img src={company.image.file} alt={company.name || 'Startup Logo'} />
                    </MyLink>
                  ) : (
                    <div className="profile__startup-logo">
                      <img src={company.image.file} alt={company.name || 'Startup Logo'} />
                    </div>
                  ))}
                {company.name && (
                  <div className="f-22 f-500 profile__startup-position">{company.name}</div>
                )}
                <div className="f-16 profile__startup-info">
                  {company.sectors &&
                    company.sectors.map(sector => <span key={sector.id}>{sector.name}</span>)}
                  {company.country && <span>{company.country} based</span>}
                  {company.foundation_date && (
                    <span>Started in {moment(company.foundation_date).format('YYYY')}</span>
                  )}
                </div>
                {company.site && <Linker value={company.site} className="f-16 f-400" />}
              </div>

              {!!company?.co_founders?.length && (
                <div className="profile__startup-founders">
                  {company.co_founders.map(founder => {
                    return (
                      <MyLink
                        href={`/founders/${founder.id}`}
                        className="profile__startup-founder"
                        key={founder.id}
                      >
                        <Avatar
                          size={50}
                          src={founder.avatar.file}
                          alt={founder.full_name}
                          nameInitials={getNameInitials(founder.first_name, founder.last_name)}
                        />
                        <div className="f-18 f-500 tl">{founder.full_name}</div>
                        <div className="profile__startup-founder-role f-14 f-400">Co-founder</div>
                      </MyLink>
                    );
                  })}
                </div>
              )}

              {user && user.role === ROLES.founder && (
                <>
                  {/*{!hasAcceptedBankDetails && (*/}
                  {/*  <WarningBlock className="profile__startup-warning">*/}
                  {/*    Company bank details are not provided.*/}
                  {/*  </WarningBlock>*/}
                  {/*)}*/}
                  <RoundButton
                    label="Invite co-founders"
                    className="profile__startup-link"
                    onClick={() => dispatch(setModals({ type: MODAL_TYPES.co_founder_invitation }))}
                    disabled={user.status !== USER_STATUSES.verified}
                    fillBackground
                  />
                </>
              )}

              {company.description && (
                <div className="profile__startup-desc">
                  <TruncatedTextByLength text={nl2br(company.description)} className="f-16" />
                </div>
              )}
              <div className="profile__startup-address f-14 f-400">
                {[
                  company.full_name,
                  company.registration_number,
                  company.address,
                  company.email,
                  company.phone_number,
                ]
                  .filter(i => i)
                  .join(', ')}
              </div>
              {company.promo && company.promo.image && (
                <div className="profile__startup-promo">
                  <MyLink href={company.site || '#'} isExternal>
                    <img src={company.promo.image.file} alt={company.promo.image.file.name} />
                  </MyLink>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="profile__startup-empty f-16 f-400">
            You did not provide startup details
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
