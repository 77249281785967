import React from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { CenterModalWrapper } from '../../components/ModalTypes/center';
import { prettyFloatMoney } from '../../helpers/utils';
import { MoneyField } from '../../components/UI/MoneyField';
import {
  DEFAULT_INPUT_LIMIT,
  DEFAULT_MAX_GOAL,
  DEFAULT_MIN_GOAL,
} from '../../containers/FundraisingModule/constants';
import s from './index.module.scss';

const VALIDATION_SCHEMA = (min, max) =>
  Yup.object().shape({
    goal: Yup.number()
      .min(min, `Minimal amount ${prettyFloatMoney(min)}`)
      .max(max, `Maximum amount ${prettyFloatMoney(max)}`)
      .required('Goal is required'),
  });

export const GoalEditModal = ({ amount, onSubmit, onClose }) => {
  const { data: status } = useSelector(state => state.fundraisingStore.status);
  const { values, errors, setValues, setFieldValue, handleSubmit } = useFormik({
    validationSchema: VALIDATION_SCHEMA(
      (status && status.min_goal) || DEFAULT_MIN_GOAL,
      (status && status.max_goal) || DEFAULT_MAX_GOAL
    ),
    initialValues: {
      goal: amount.toString(),
    },
    onSubmit,
  });

  return (
    <CenterModalWrapper onClose={onClose}>
      <form className={s.wrapper} onSubmit={handleSubmit}>
        <MoneyField
          name="goal"
          label="Edit fundraising goal"
          onChange={(_, goal) =>
            setValues({
              ...values,
              goal,
              has_accepted_terms: false,
            })
          }
          value={values.goal}
          onClear={() => setFieldValue('goal', '')}
          max={(status && status.max_goal + DEFAULT_INPUT_LIMIT) || DEFAULT_INPUT_LIMIT}
          error={errors.goal}
          className="fundraising-goal-form__goal"
        />
        <button type="submit" onSubmit={handleSubmit} className={s.save_btn}>
          Save
        </button>
      </form>
    </CenterModalWrapper>
  );
};
