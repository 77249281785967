import React from 'react';
import { createPortal } from 'react-dom';
import s from './index.module.scss';

export const CenterModalWrapper = ({ children, onClose }) => {
  return createPortal(
    <div className={s.wrapper}>
      <div className={s.backdrop} onClick={onClose} />
      <div className={s.content}>{children}</div>
    </div>,
    document.body
  );
};
